import { Component } from "react";

export default class Experience extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="experience">
  <div className="colorlib-narrow-content">
    <div className="row">
      <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
        <span className="heading-meta">Experience</span>
        <h2 className="colorlib-heading animate-box">Work Experience</h2>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="timeline-centered">
          <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
            <div className="timeline-entry-inner">
              <div className="timeline-icon color-4">
                <i className="icon-pen2" />
              </div>
              <div className="timeline-label">
                <h2><a href="#">FUNARBE - Full Stack developer and System analyst </a> <span>2024- At the moment</span></h2>
                <p>Software development in the Smart Cities Project. I am responsible for developing healthcare systems with JavaScript, such as password panels and APIs for the system's mobile application to facilitate the population's access to medical care. I am also responsible for research aimed at combating dengue with the help of drones, where we use a neural network developed from scratch to identify dengue outbreaks through the drone's camera, carry out the count and inform the inspector if deeper inspection is necessary in the area.</p>
              </div>
            </div>
          </article>
          <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
            <div className="timeline-entry-inner">
              <div className="timeline-icon color-4">
                <i className="icon-pen2" />
              </div>
              <div className="timeline-label">
                <h2><a href="#">Fiotec - Researcher and developer Python </a> <span>2023-2024</span></h2>
                <p>I work with research focused on health and combating diseases transmitted by mosquitoes. I mainly use Python and Yolo development to meet demands. (Further information cannot be disclosed due to contractual clauses).</p>
              </div>
            </div>
          </article>
          <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
            <div className="timeline-entry-inner">
              <div className="timeline-icon color-4">
                <i className="icon-pen2" />
              </div>
              <div className="timeline-label">
                <h2><a href="#">Quanyx Softhouse - Full Stack Developer and service integration </a> <span>2023-2024</span></h2>
                <p>I worked as a Full Stack web developer at Quanyx Softhouse. As part of my work, I have primarily focused on NodeJs and React technology in conjunction with databases and docker, and I am continually deepening my knowledge in these areas to ensure I can create high-quality software solutions.</p>
              </div>
            </div>
          </article>
          <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
            <div className="timeline-entry-inner">
              <div className="timeline-icon color-4">
                <i className="icon-pen2" />
              </div>
              <div className="timeline-label">
                <h2><a href="#">DERUN - Backend python developer and service integration </a> <span>2021-2023</span></h2>
                <p>Back-end developer in python, solving routing problems and managing the integration of solutions created by the team. Along with this, project management methods were used for deliveries and weekly meetings with the team and fortnightly deliveries. Joint project with the research institute ElDorado and Motorola.</p>
              </div>
            </div>
          </article>
          <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
            <div className="timeline-entry-inner">
              <div className="timeline-icon color-5">
                <i className="icon-pen2" />
              </div>
              <div className="timeline-label">
                <h2><a href="#">ICTIOBIOMETRIA - Back-end WEB development project in NodeJs</a> <span>2020-2022</span></h2>
                <p>Development of an API for managing images of fish and users for the IctioBiometria application. Joint project with the Department of Biology at the Federal University of Rondônia and Ecoporé.</p>
              </div>
            </div>
          </article>
          <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
            <div className="timeline-entry-inner">
              <div className="timeline-icon color-none">
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</section>

      </div>
    )
  }
}