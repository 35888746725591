import { Component } from "react"

export default class About extends Component{
  render() {
    return (
      <section className="colorlib-about" data-section="about">
<div className="colorlib-narrow-content">
  <div className="row">
    <div className="col-md-12">
      <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
        <div className="col-md-12">
          <div className="about-desc">
            <span className="heading-meta">About Us</span>
            <h2 className="colorlib-heading">Who Am I?</h2>
            <p><strong>Hi I'm Murilo Fuza da Cunha</strong> Graduated and Influenced by IFRO in the area of ​​computing and also graduated in Computer Science at UNIR. I currently develop in NodeJS in the Backend and also Frontend in React, but I also have project experience with Ecoporé, ElDorado, Fiocruz, Fiotec and FUNARBE in backend with Python, NodeJs and PrismaJs along with cloud computing and server configurations with Nginx , PM2, Docker and Traefik.</p>
            <p>Used to working in teams of 10 people and also on projects with just one development partner, weekly delivery meetings already familiar with methods such as SCRUM to manage projects and weekly delivery systems. I am able to speak at meetings with the team and develop dialogue, returning to what should be presented as a result, discussed or highlighted.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
    )
  }
}