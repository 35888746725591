import { Component } from "react";

export default class Skills extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-skills" data-section="skills">
  <div className="colorlib-narrow-content">
    <div className="row">
      <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
        <span className="heading-meta">My Specialty</span>
        <h2 className="colorlib-heading animate-box">My Skills</h2>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
        <p>I'm a Mid developer so I have development concepts and knowledge of some technologies.</p>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="progress-wrap">
          <h3>NodeJs</h3>
          <div className="progress">
            <div className="progress-bar color-1" role="progressbar" aria-valuenow={80} aria-valuemin={0} aria-valuemax={100} style={{width: '80%'}}>
              <span>80%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="progress-wrap">
          <h3>Python</h3>
          <div className="progress">
            <div className="progress-bar color-1" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}>
              <span>70%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInRight">
        <div className="progress-wrap">
          <h3>Postgress</h3>
          <div className="progress">
            <div className="progress-bar color-2" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}>
              <span>70%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="progress-wrap">
          <h3>Prisma & TypeORM</h3>
          <div className="progress">
            <div className="progress-bar color-3" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: '60%'}}>
              <span>80%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInRight">
        <div className="progress-wrap">
          <h3>TypeScript</h3>
          <div className="progress">
            <div className="progress-bar color-4" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: '60%'}}>
              <span>70%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="progress-wrap">
          <h3>React</h3>
          <div className="progress">
            <div className="progress-bar color-5" role="progressbar" aria-valuenow={20} aria-valuemin={0} aria-valuemax={100} style={{width: '20%'}}>
              <span>35%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInRight">
        <div className="progress-wrap">
          <h3>AWS</h3>
          <div className="progress">
            <div className="progress-bar color-6" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100} style={{width: '40%'}}>
              <span>30%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 animate-box" data-animate-effect="fadeInRight">
        <div className="progress-wrap">
          <h3>HTML</h3>
          <div className="progress">
            <div className="progress-bar color-5" role="progressbar" aria-valuenow={30} aria-valuemin={0} aria-valuemax={100} style={{width: '30%'}}>
              <span>30%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="progress-wrap">
          <h3>DOCKER</h3>
          <div className="progress">
            <div className="progress-bar color-6" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{width: '50%'}}>
              <span>60%</span>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</section>
      </div>
    )
  }
}