import { Component } from "react";

export default class Work extends Component {
  render() {
    return (
      <section className="colorlib-work" data-section="work">
  <div className="colorlib-narrow-content">
    <div className="row">
      <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
        <span className="heading-meta">My Works</span>
        <h2 className="colorlib-heading animate-box">Projects</h2>
      </div>
    </div>
    <div className="row">

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/email.png)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://simple-mail-landing.vercel.app/" rel="noreferrer">Mail Service</a></h3>
              <span>Simple Mail Service -  Simple Mail is a prebuilt application for mail sending, supporting multiple templates and variables.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/ictio.webp)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://ictiobiometria.unir.br" rel="noreferrer">Api REST</a></h3>
              <span>Ictiobiometria API -  A fish management project that stores images and data about the fish and also about the
               ladder and location, in addition to measurements, all of which data is received from an application
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/job.png)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://www.ekev.com.br/" rel="noreferrer">Landing Page</a></h3>
              <span>Landing Page Ekev -  Built with you in mind, a visionary leader, who seeks to maximize your team's productivity and success, our program is the ideal tool to make team management more efficient, collaborative and organized. With our intuitive and easy-to-use system, you'll have all the tools you need to lead your team in one place. From project planning to task delegation.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/clara-conta.png)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://github.com/MuriloFuza/Conta-clara" rel="noreferrer">Conta clara</a></h3>
              <span>Full Application WEB -  Conta Clara is a mini finance web app developed especially for students at UNIR (Federal University of Rondônia) who want to keep their personal finances organized. With UNIR Conta Clara, you can easily record your cash inflows and outflows in reais, in addition to monitoring an intuitive dashboard that presents a clear view of your expenses. With simple and efficient features, UNIR Conta Clara allows you to have total control over your finances, helping you make smart decisions and achieve a balanced financial life. Keep your finances in order while you focus on your studies with UNIR Conta Clara.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/youtube.png)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://github.com/MuriloFuza/youtube_transcript" rel="noreferrer">Python API Youtube</a></h3>
              <span>Youtube Transcript API-  API for video search and transcription of video subtitles.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/whatmycep.png)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://github.com/MuriloFuza/api-rentx" rel="noreferrer">WhatMyCep?</a></h3>
              <span>WhatMyCep -  Website in html that provides a tool capable of delivering the user's zip code through the entered address
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/carro.png)'}}>
          <div className="desc">
            <div className="con">
              <h3><a target="_blank" href="https://whatmycep.online" rel="noreferrer">API RentX</a></h3>
              <span>RentX -  API for car rental (does not make payments)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/derun.jpeg)'}}>
          <div className="desc">
            <div className="con">
              <h3><a href="#">API, Researches and Cloud Computing</a></h3>
              <span>DERUN -  A research and development project in conjunction with the Federal University of Rondônia and the
               ElDorado Research Institute
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    )
  }
}