import './App.css';
import { Component } from 'react';
import Home from './components/home';
import About from './components/about';
import Sidebar from './components/sidebar';
import Service from './components/service';
import Skills from './components/skills';
import Education from './components/education';
import Experience from './components/experience';
import Work from './components/work';
import Contact from './components/contact';

class App extends Component {
  render(){
    return(
    <div id='colorlib-page'>
      <div id='container-wrap'>
      <Sidebar></Sidebar>
        <div id='colorlib-main'>
          <Home></Home>
          <About></About>
          <Service></Service>
          <Skills></Skills>
          <Education></Education>
          <Experience></Experience>
          <Work></Work>
          <Contact></Contact>
        </div>
      </div>

    </div>
    )
  }
}

export default App;
